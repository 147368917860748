import React from "react"
import {  graphql } from "gatsby"
import Img from "gatsby-image"
export default function Image({ data }) {
  return (
    <div>
      <h1>Hello gatsby-image</h1>
      <Img
        fluid={data.pic01.childImageSharp.fluid}
        alt="Gatsby Docs are awesome"
      />
    </div>
  )
} 

export const query = graphql`
  query MyQuery{
    pic01: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`